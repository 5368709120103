import { Component, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Inject, ElementRef } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SorcService } from '@app/services/sorc.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { Observable, of } from 'rxjs';
import { share, mergeMap } from 'rxjs/operators';
import { CarsSpecFilters } from '@app/domain/CarsSpecFilters';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { _MatTabGroupBase } from '@angular/material/tabs';
import * as lodash from 'lodash'; //copy array

@Component({
  selector: 'app-new-competitor-vehicle',
  templateUrl: './new-competitor-vehicle.component.html',
  styleUrls: ['./new-competitor-vehicle.component.scss', './new-competitor-vehicle-slider.component.scss']
})


export class NewCompetitorVehicleComponent implements OnInit, AfterViewChecked {

  readonly INCREASE = 1;
  readonly DECREASE = 0;
  @ViewChild('availableCarsGrid') availableCarsGrid;
  @ViewChild('selectedCarsGrid') selectedCarsGrid;

  display = "none";

  firstCSPage = {};
  selCarRows = [];
  numberPage = 0;
  selectedCount = 0;

  currencyID: number;
  currencySign: string;
  idCol = 0;
  scenario = {};
  filterParams: any = {
    netPrice: {
      range: 50,
      selected: 10
    },
    horsePower: {
      range: 50,
      selected: 10
    },
    height: {
      range: 50,
      selected: 10
    },
    length: {
      range: 50,
      selected: 10
    },
  };

  possibleMakes = {};
  baseVehicle: any = {
    market: "",
    make: "",
    model: "",
    fuel: "",
    body: "",
    drive: "",
    transmission: "",
    horsePower: 0,
    netPrice: 0,
    height: 0,
    vehicleLength: 0,
    segment: ""
  }

  selectedFilters: string[] = [
    "segment",
    "body",
    "fuel",
    "drive",
    "transmission",
    "horsePower",
    "netPrice"
  ];

  public filtersMETA = {
    segment: { dictionaryKey: "intsegmentation", filterKey: "segmentation", label: "SEGMENT", quotes: true, type: "set" },
    body: { type: "set", label: "BODY", filterKey: "intBodyType", dictionaryKey: "intbodytype", quotes: true },
    fuel: { type: "set", label: "FUEL", filterKey: "fuelType", dictionaryKey: "fueltype", quotes: true },
    drive: { type: "set", label: "DRIVE", filterKey: "driveType", dictionaryKey: "drivetype", quotes: true },
    transmission: { type: "set", label: "TRANSMISSION", filterKey: "transmissionType", dictionaryKey: "transmissiontype", quotes: true },
    horsePower: { type: "int", label: "HORSE POWER", suffix: "PS", filterKey: "power", rangeKey: "maxminPower" },
    netPrice: { type: "float", subType: "currency", label: "NET PRICE", suffix: "&euro;", filterKey: "currentNetPrice", rangeKey: "maxminCurrentNetPrice" },
    height: { type: "int", label: "HEIGHT", suffix: "mm", filterKey: "height", rangeKey: "maxminHeight" },
    vehicleLength: { type: "int", label: "LENGTH", suffix: "mm", key: "length", filterKey: "length", rangeKey: "maxminLength" }
  };

  selectedAvailableNodes: any;
  filterTemplate: any[] = [];
  filterLists: Map<string, string[]> = new Map;
  filteresNotYetLoaded = true;
  totalFilteredItems = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public dataFromMain: any, private _specsService: SorcService,
    public fb: FormBuilder, private dialogRef: MatDialogRef<NewCompetitorVehicleComponent>,
    private http: HttpClient, private spinner: NgxSpinnerService, private messageService: HandleUiMessagesService) {

    this.idCol = this.dataFromMain.idCol;
    this.currencyID = this.dataFromMain.currencyID;
    this.currencySign = getCurrencySignByID(this.currencyID);
    this.getCarValues(this.dataFromMain.dataTCO);

    if (!!environment.filterParams && Object.keys(environment.filterParams).length != 0) {
      this.filterParams = environment.filterParams;
    }

  }

  getCarValues(dataTCO) {
    const jp = require('jsonpath');

    this.baseVehicle.body = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'body\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.market = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'market\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.model = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'model\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.make = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'make\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.segment = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'segment\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.fuel = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'fuel\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.drive = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'drive\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.transmission = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'transmission\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.horsePower = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'horsePower\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.netPrice = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'netListPrice\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.height = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'height\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.baseVehicle.vehicleLength = jp.query(dataTCO, '$[?(@.field==\'carSpecs\')].children[?(@.field==\'length\')]' + '.values[\'' + this.idCol + '\']')[0];

    this.scenario["duration"] = jp.query(dataTCO, '$[?(@.field==\'scenario\')].children[?(@.field==\'duration\')]' + '.values[\'' + this.idCol + '\']')[0];
    this.scenario["distance"] = jp.query(dataTCO, '$[?(@.field==\'scenario\')].children[?(@.field==\'distance\')]' + '.values[\'' + this.idCol + '\']')[0];
  }

  ngOnInit(): void {

    if (this.baseVehicle.market == null || this.baseVehicle.market == "") {
      this.messageService.showMessage("There are no market for search!");
      return;
    }

    let valuesFor = "make";
    let crit = { market: this.baseVehicle.market };

    this.sshow("loadScope")

    this._specsService.getScope(crit, valuesFor).subscribe((data) => {
      this.possibleMakes = data.filter(item => item != this.baseVehicle.make);
      this.loadDefaultCompetitors();
    },
      error => {
        this.shide("loadScope");
        this.messageService.showMessage("Error loading list make");
      });
  }


  loadDefaultCompetitors() {
    let csr: any = this.fillDefaultFiltersRequest();

    let body = {
      req: csr,
      car: this.baseVehicle,
      filterParams: this.filterParams
    }

    this.http.post<any>(environment.defaultCompEndpoint, body).subscribe(

      (data: any) => {
        // console.log(data, "first load");
        let isEmpty = data.filtersResult.carSpecs.content.length == 0;

        if (data.isLCV) {
          this.selectedFilters.push("vehicleLength")
          this.selectedFilters.push("height")
          if (isEmpty) {
            data.filtersResult.allowedValues.height.min = this.calcValueRound(this.baseVehicle.height, this.filterParams.height.range, this.DECREASE);
            data.filtersResult.allowedValues.height.max = this.calcValueRound(this.baseVehicle.height, this.filterParams.height.range, this.INCREASE);
            data.filtersResult.allowedValues.vehicleLength.min = this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.range, this.DECREASE);
            data.filtersResult.allowedValues.vehicleLength.max = this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.range, this.INCREASE);
          }
        }

        if (isEmpty) {
          data.filtersResult.allowedValues.horsePower.min = body.req.criteria.horsePower.from;
          data.filtersResult.allowedValues.horsePower.max = body.req.criteria.horsePower.to;
          data.filtersResult.allowedValues.netPrice.min = body.req.criteria.netPrice.from;
          data.filtersResult.allowedValues.netPrice.max = body.req.criteria.netPrice.to;
          this.messageService.showMessage4Duration("CCE was not able to find any competitor for the selected vehicle.\nPlease expand your filters to find a suitable competitor.", 12000);
          this.applyChangesToComboFilters(data.filtersResult);
          this.shide("loadDefault");
        } else {
          this.applyChangesToComboFilters(data.filtersResult);
          this.selectDefault(data.isLCV);
        }
      },
      error => {
        this.shide("loadDefault");
        this.messageService.showMessage("Error loading Filters");
      });

  }

  selectDefault(isLCV) {
    this.selectFilterValue("body", this.baseVehicle.body);
    this.selectFilterValue("fuel", this.baseVehicle.fuel);
    this.selectFilterValue("segment", this.baseVehicle.segment);
    this.selectFilterValue("drive", this.baseVehicle.drive);
    this.selectFilterValue("transmission", this.baseVehicle.transmission);

    this.filterTemplate["horsePower"].from = this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.selected, this.DECREASE);
    this.filterTemplate["horsePower"].to = this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.selected, this.INCREASE);
    this.filterTemplate["netPrice"].from = this.calcValue(this.baseVehicle.netPrice, this.filterParams.netPrice.selected, this.DECREASE);
    this.filterTemplate["netPrice"].to = this.calcValue(this.baseVehicle.netPrice, this.filterParams.netPrice.selected, this.INCREASE);

    if (isLCV) {
      this.filterTemplate["height"].from = this.calcValueRound(this.baseVehicle.height, this.filterParams.height.selected, this.DECREASE);
      this.filterTemplate["height"].to = this.calcValueRound(this.baseVehicle.height, this.filterParams.height.selected, this.INCREASE);
      this.filterTemplate["vehicleLength"].from = this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.selected, this.DECREASE);
      this.filterTemplate["vehicleLength"].to = this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.selected, this.INCREASE);
    }
    this.customFilterChange("internalLoad");
  }

  selectFilterValue(filter, value) {
    this.filterTemplate[filter]["values"].forEach(element => {
      if (element == value) {
        this.filterTemplate[filter].selected.add(value);
        this.filterTemplate[filter]["values"] = this.filterTemplate[filter]["values"], filter;
        return
      }
    });

  }


  fillDefaultFiltersRequest() {
    let req: any = { criteria: {}, sortOrder: undefined, colSort: undefined };

    req["criteria"]["make"] = this.possibleMakes;
    req['currencyId'] = this.currencyID;
    req['toFilter'] = lodash.cloneDeep(this.selectedFilters);
    req["criteria"]["horsePower"] = { "from": this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.range, this.DECREASE), "to": this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.range, this.INCREASE) };
    req["criteria"]["netPrice"] = { "from": this.calcValue(this.baseVehicle.netPrice, this.filterParams.netPrice.range, this.DECREASE), "to": this.calcValue(this.baseVehicle.netPrice, this.filterParams.netPrice.range, this.INCREASE) };
    req["criteria"]["market"] = this.baseVehicle.market;
    req["criteria"]["model"] = {};
    req["criteria"]["saleEnd"] = {};
    req["criteria"]["saleStart"] = {};
    req["criteria"]["trimLevel"] = {};

    return req;
  }

  calcValueRound(value: number, percent: number, operant): number {
    let retval = Math.round(this.calcValue(value, percent, operant));
    return retval;
  }

  calcValue(value: number, percent: number, operant): number {
    let retval: number = 0;
    if (!!value && !!percent) {
      percent = percent / 100;
      if (operant == this.INCREASE) {
        retval = Number(value) + (value * percent);
      } else {
        retval = Number(value) - (value * percent);
      }
    }
    return retval;
  }

  applyChangesToComboFilters(cceResponse) {
    let allowedValues = (cceResponse.allowedValues) ? cceResponse.allowedValues : {};
    allowedValues["horsePower"] = { "min": this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.range, this.DECREASE), "max": this.calcValueRound(this.baseVehicle.horsePower, this.filterParams.horsePower.range, this.INCREASE) };
    allowedValues["netPrice"] = { "min": this.calcValueRound(this.baseVehicle.netPrice, this.filterParams.netPrice.range, this.DECREASE), "max": this.calcValueRound(this.baseVehicle.netPrice, this.filterParams.netPrice.range, this.INCREASE) };
    allowedValues["height"] = { "min": this.calcValueRound(this.baseVehicle.height, this.filterParams.height.range, this.DECREASE), "max": this.calcValueRound(this.baseVehicle.height, this.filterParams.height.range, this.INCREASE) };
    allowedValues["vehicleLength"] = { "min": this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.range, this.DECREASE), "max": this.calcValueRound(this.baseVehicle.vehicleLength, this.filterParams.length.range, this.INCREASE) };

    for (let i = 0; i < this.selectedFilters.length; i++) {
      let key = this.selectedFilters[i];
      if (this.filtersMETA.hasOwnProperty(key) && allowedValues.hasOwnProperty(key)) {
        this.filterTemplate[key] = this._specsService.aggregate(this.filtersMETA[key], allowedValues[key], this.currencySign);
        console.log('fixing all, current combo :' + key, allowedValues[key]);
      } else {
        console.log('fixing all, missing key <' + key + '>', this.filtersMETA, allowedValues)
      }
    }

  }

  loadPagesCarsSpecs(filters: CarsSpecFilters): Observable<any> {
    let req = this.turnFilterToSpecRequest(this.getCurrentFilter(), this.currencyID);
    return this._specsService.postForFilters(req, filters).pipe(
      mergeMap(
        cceResponse => {
          // console.log('loadPagesCarsSpecs', cceResponse);
          return of(cceResponse);
        }
      )
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  finishSelection(selectedScenario) {
    this.dialogRef.close({ cars: this.selCarRows, scens: [this.scenario] });
  }

  getCurrentFilter() {
    const filter = {};
    if (this.filterTemplate) {
      console.log('real template', this.filterTemplate);
      Object.keys(this.filterTemplate).forEach(key => {
        if (this.isRange(key)) {
          // console.log('will use ', this.filterTemplate[key].from, this.filterTemplate[key].to), key;
          filter[key] = this.filterTemplate[key];
        } else if (this.isList(key)) {
          // console.log('will use ', this.filterTemplate[key].selected);
          filter[key] = this.filterTemplate[key].selected;
        }
      });
    }

    return filter;
  }

  checkEnableButton(): void {
  }

  onClickNextPage() {
    this.finishSelection([]);
  }

  ngAfterViewChecked(): void {
    this.checkEnableButton();
  }

  private lastFilterTimer: NodeJS.Timeout = null;


  showSelectedCarsInAvailableCarsGrid(maxRetries) {
    let allSelectedCars = this.selectedCarsGrid.selectedCars;
    if (allSelectedCars !== null && allSelectedCars.length > 0) {
      let allRenderedRows = this.availableCarsGrid.theGrid.api.getRenderedNodes();
      for (let i in allRenderedRows) {
        for (let j in allSelectedCars) {
          let rowNode = allRenderedRows[i];
          if (!rowNode.data) {
            //TODO ISPASOV not YET loaded
            // console.log(rowNode.data) is later filled!
            if (maxRetries > 0) {
              console.log('grid is not ready, one retry!', rowNode);
              setTimeout(() => this.showSelectedCarsInAvailableCarsGrid(maxRetries - 1), 500);
            } else {
              console.log('grid is not ready, no retries!', rowNode);
            }
            return;
            //TODO ISPASOV not YET loaded
            // console.log(rowNode.data) is later filled!
          } else {
            if (rowNode.data.carID === allSelectedCars[j].carID) {
              rowNode.setSelected(true);
            }
          }
        }
      }
    }
  }

  executeApplyFilterChange(activeCombo) {
    // console.log('Request to fix the filter for: ' + activeCombo, this.selectedFilters);
    let filters: CarsSpecFilters = new CarsSpecFilters();
    let sort = this.availableCarsGrid.getGridSorting();
    if (sort && sort[0]) {
      filters.setColSort(sort[0].colId);
      filters.setSortOrder(sort[0].sort);
    }

    let req = this.turnFilterToSpecRequest(this.getCurrentFilter(), this.currencyID);
    return this._specsService.postForFilters(req, filters).pipe(
      mergeMap(cceResponse => {
        if (cceResponse.carSpecs.content.length == 0) {
          this.messageService.showMessage4Duration("CCE was not able to find any competitor for the selected vehicle.\nPlease expand your filters to find a suitable competitor.", 12000);
        }
        console.log('applying response for index ' + ' and ' + activeCombo, cceResponse);
        this.applyChangesToBasket(cceResponse);
        if (activeCombo === null) {
          this.applyChangesToComboFilters(cceResponse);
        }
        return of(this.filterTemplate);
      })
    );
  }

  turnFilterToSpecRequest(filter, currencyID) {
    let result = {};
    let criteria = {};
    let toFilter: string[] = [];
    Object.keys(filter).forEach(function (k) {
      // console.log(k, typeof(filter[k]));
      let currentCrit = filter[k];
      if (typeof (filter[k]) === 'object' && !(currentCrit instanceof Set)) {
        currentCrit = { ...filter[k] };
        delete (currentCrit['options']);
        criteria[k] = currentCrit;
      } else if (currentCrit instanceof Set) {
        criteria[k] = [];
        currentCrit.forEach(element => {
          criteria[k].push(element);
        });
      } else {
        criteria[k] = currentCrit;
      }
    });
    criteria["make"] = this.possibleMakes;
    criteria["market"] = this.baseVehicle.market;
    result['criteria'] = criteria;
    result['toFilter'] = toFilter;
    result['currencyId'] = currencyID;
    return result;
  }

  applyChangesToBasket(cceResponse) {
    let data: any = {};
    data.content = cceResponse.carSpecs.content;
    data.totalElements = cceResponse.carSpecs.totalElements;
    // console.log("cceResponse applyChangesToBasket", data);
    this.availableCarsGrid.replaceDataSource(data);
    this.totalFilteredItems = cceResponse.carSpecs.totalElements;
  }

  toggleSelection(element: string, item: string) {
    if (this.filterTemplate[element].selected) {
      if (this.filterTemplate[element].selected.has(item)) {
        this.filterTemplate[element].selected.delete(item);
      } else {
        this.filterTemplate[element].selected.add(item);
      }
    }
    // load data
    this.customFilterChange(element);
    // console.log('selected', this.filterTemplate[element].selected);
  }

  customFilterChange(event) {
    if (this.lastFilterTimer != null) {
      clearTimeout(this.lastFilterTimer);
      this.lastFilterTimer = null;
    }
    //load data
    this.lastFilterTimer = setTimeout(() => this.internalFilterChange(event), 500);
  }

  internalFilterChange(event) {
    // console.log('filter change', this.filterTemplate[event]);
    // this.selectedFilters = this.nestedForm.value.filters;
    if (event && event != "internalLoad") {
      this.sshow((event) ? event : 'empty filter');
    }
    this.executeApplyFilterChange(event).subscribe((x) => {
      this.shide((event) ? event : 'empty filter');
    },
      error => {
        this.shide((event) ? event : 'empty filter');
        this.messageService.showMessage("Error loading list " + event);
      });
  }

  logg(key) {
    console.log(key, "FMTD");
  }

  isRange(key: string) {
    return (this.filtersMETA[key].type === 'int' || this.filtersMETA[key].type === 'float') && this.filterTemplate.hasOwnProperty(key) && (this.filterTemplate[key].from !== null) && (this.filterTemplate[key].to !== null);
  }

  isNaN(key: string) {
    // console.log('checking for null', this.filterTemplate[key], this.filtersMETA[key]);
    return (this.filtersMETA[key].type === 'int' || this.filtersMETA[key].type === 'float') && this.filterTemplate.hasOwnProperty(key) && (this.filterTemplate[key].from === null || this.filterTemplate[key].to === null);
  }

  isList(key: string) {
    return this.filtersMETA[key].type === 'set';
  }

  getStyle(elem, item): string {
    return (this.filterTemplate[elem]['selected'].has(item)) ? 'cce-filter-item-selected' : 'cce-filter-item';
  }

  getFilterValuesAsArray(key) {
    if (this.filterLists.has(key)) {
      return this.filterLists.get(key);
    } else {
      if (!this.filterTemplate[key] || !this.filterTemplate[key].values) {
        // console.log(key, "dfdsfd")
        return [];
      }
      let fixed: string[] = Array.from(this.filterTemplate[key].values);

      if (fixed && fixed.length > 0) {
        fixed = fixed.sort();
        for (let index = 0; index < fixed.length; index++) {
          const element = fixed[index];
          // // console.log(element,"oSort");
          // console.log(Array.from(this.filterTemplate[filter]?.selected , element,"selected , element");

          if (Array.from(this.filterTemplate[key]?.selected).includes(element)) {
            if (element != "") {
              fixed.splice(index, 1);
              fixed.splice(1, 0, element);
            }
            this.filterLists.set(key, fixed);
            return fixed;
          }
        }
      }

      return fixed;
    }
  }

  semaphor = 0;
  sshow(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }

  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

}


