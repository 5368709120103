import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '@app/services/account.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MixPanelService } from '@app/services/mix-panel.service';
import { NotificationService } from '@app/services/notification.service';
import { NotificationShowComponent } from '../notification-show/notification-show.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    returnURL: string;
    logoYear = new Date();

    constructor(
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private router: Router,
        private messageService: HandleUiMessagesService,
        private dialog: MatDialog,
        private mixPanelServ: MixPanelService, private newsToShow: NotificationService
    ) { }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.returnURL = this.route.snapshot.queryParams['returnURL'] || '/';
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        let username = this.loginForm.controls.username.value
        this.accountService.login(username, this.loginForm.controls.password.value)
            .pipe(first())
            .subscribe(
                user => {
                let endpoint = '/cce/transactions/login';
                this.mixPanelServ.logData({ eventKey: "login" }, endpoint);
                this.router.navigate([this.returnURL]);
                this.checkNotifiations();
                },
                error => {
                    this.loading = false;

                    if (error.status == 405) {
                      this.openChangePass(username);
                      return;
                    }
                    if(typeof error.error === "object") {
                        this.messageService.showMessage("Error: " + error.error.message);
                    }else {
                        this.messageService.showMessage("Error: " + error.error);
                    }

                }
            )
    }

  checkNotifiations() {
    this.newsToShow.getNotificationsToShow().subscribe(news => {
      // console.log(news, "news");
      for (let index = 0; index < news.length; index++) {
        const notification = news[index];
        this.previewNotification(notification);
      }
    }, error => {
      if (typeof error.error === "object") {
        this.messageService.showMessage("Error: " + error.error.message);
      } else {
        this.messageService.showMessage("Error: " + error.error);
      }
    });
  }

  previewNotification(notification) {
    this.dialog.open(NotificationShowComponent, {
      closeOnNavigation: false, width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container", data: notification
    }).afterClosed().subscribe(response => {
      // console.log(response, "!!this._id");
      if (!!response) {
        this.newsToShow.readNotification(response);
      }
    });
  }

  openResetPass() {
    this.router.navigate(['/resetPass']);
  }

  openChangePass(email) {
    this.router.navigate(['/changePass'], { queryParams: { email: email } });
  }

}
