import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HandleUiMessagesService } from './handle-ui-messages.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private messageService: HandleUiMessagesService) { }


  loadAllNotifications(): Observable<any> {
    return this.http.get(environment.getNotifications);
  }

  deleteById(notification) {
    return this.http.post<any>(environment.deleteNotification, notification);
  }

  readNotification(notification) {
    this.http.post<any>(environment.readNotification, notification).subscribe(response => {
      console.log("The News was tagged read!");
    }, error => {
      console.log(error.error);
    });
  }

  getNotificationsToShow(): Observable<any> {
    return this.http.get(environment.getNotificationsToShow);
  }

  getNotificationsStatus(): Observable<any> {
    return this.http.get(environment.getNotificationsStatus);
  }

  save(notification): Observable<any> {
    return this.http.post<any>(environment.saveNotification, notification);
  }

}
