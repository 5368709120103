<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
<div class="cce-header-nbc-vehicle">
  <div class="cce-col" id="admin-menu" style="width: 85%;padding-left: 15px;">
    <div class="cce-row" style="margin-top: 20px">
      <span class="header-label">NEWS PANEL</span>
    </div>
  </div>
  <div *ngIf="isAppAdmin" class="cce-row">
    <a class="admin-panel-button" (click)="onClickOpenNew()">New Notification</a>
  </div>
</div>

<mat-card class="availableCarsGrid">
  <mat-card-content style="height: 100%;width: 100%">
    <!-- id="admin-panel-grid" -->
    <ag-grid-angular myGrid class="ag-theme-balham" (cellDoubleClicked)="onCellDoubleClicked($event)"
      [gridOptions]="gridOptionsSpecs" [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>
  </mat-card-content>
</mat-card>
