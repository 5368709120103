import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { BtnMultiCellRenderer } from '../btn-multi-cell-renderer/btn-multi-cell-renderer.component';
// import { format } from 'date-fns';
import { NotificationService } from '@app/services/notification.service';
import { CheckboxCellRenderer } from '../checkbox-cell-renderer/checkbox-cell-renderer.component';
import { AccountService } from '@app/services/account.service';
import { NotificationShowComponent } from '../notification-show/notification-show.component';
import { NgxSpinnerService } from 'ngx-spinner';





@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {

  frameworkComponents: any;
  pipe = new DatePipe('en-US');
  @ViewChild('myGrid') myGrid: AgGridAngular;
  rowData = [];
  isAppAdmin = false;

  public columnDefs;

  constructor(private dialog: MatDialog, private accountService: AccountService, private notServ: NotificationService,
    private spinner: NgxSpinnerService, private messageService: HandleUiMessagesService) {
    let that = this;
    this.isAppAdmin = this.accountService.hasRole("cceAppAdmin");

    this.columnDefs = [

      {
        headerName: 'VALID FROM', field: 'validFrom', valueFormatter: (params: any) => {
          return params.value ? this.pipe.transform(params.value, 'dd/MM/yyyy') : '';
        }, width: 150
      }, {
        headerName: 'VALID TO', field: 'validTo', valueFormatter: (params: any) => {
          return params.value ? this.pipe.transform(params.value, 'dd/MM/yyyy') : '';
        }, width: 150
      }, {
        headerName: 'HEADER', field: 'header', flex: 1
      },
      // {
      //   headerName: 'MESSAGE', field: 'message', flex: 2
      // },
      ...(this.isAppAdmin ? [{
        headerName: 'PRIORITY', field: 'priority', cellRenderer: 'checkboxCellRenderer', width: 100
      }, {
        headerName: `REMOVE`, field: 'ID', cellRenderer: 'btnMultiCellRenderer',
        cellRendererParams: {
          parent: this,
          hasDelete: true
        }, width: 100
      }] : []),

    ];

    this.frameworkComponents = { btnMultiCellRenderer: BtnMultiCellRenderer, checkboxCellRenderer: CheckboxCellRenderer };

    this.gridOptionsSpecs = {
      context: this,
      rowHeight: 34,
      rowData: this.rowData,
      enableRangeSelection: true,
      columnDefs: this.columnDefs,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      rowClassRules: {
        'priority': function (params) {
          return params.node.data.priority;
        },
      }
    };
  }

  gridOptionsSpecs: GridOptions;

  hasPolicy(policies, name) {
    for (let i = 0; i < policies.length; ++i) {
      if (policies[i].name === name) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.refreshGrid();
  }

  onCellDoubleClicked(event) {
    if (this.isAppAdmin) {
      this.openAddNotificationModal({ notification: event.data });
    } else {
      this.preview(event.data)
    }
  }

  preview(notification) {
    this.dialog.open(NotificationShowComponent, {
      closeOnNavigation: false, width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container", data: notification
    }).afterClosed().subscribe(response => {
      // console.log(response, "!!this._id");
      if (!!response && !!response._id) {
        this.notServ.readNotification(response);
      }
    });
  }

  onClickOpenNew() {
    this.openAddNotificationModal({});
  }

  openAddNotificationModal(notification): void {
    this.dialog.open(NotificationDialogComponent, { width: "80vw", minWidth: "1000px", maxWidth: "1200px", height: "70vh", panelClass: "cce-dialog-container", data: notification })
      .afterClosed().subscribe(result => {
        if (result) {
          this.refreshGrid();
        }
      });
  }

  refreshGrid(): void {
    this.rowData = [];

    this.spinner.show();
    this.notServ.loadAllNotifications().subscribe((response: any) => {
      this.spinner.hide();
      this.rowData = response;
      this.gridOptionsSpecs.api.setRowData(this.rowData);
    }, error => {
      this.spinner.hide();
      if (typeof error.error === "object") {
        this.messageService.showMessage("Error: " + error.error.message);
      } else {
        this.messageService.showMessage("Error: " + error.error);
      }
    });

  }

  deleteById(notification: any) {
    let message = "DO YOU REALLY WANT TO REMOVE THE NOTIFICATION?";
    var dialogRef = this.dialog.open(ConfirmDialogComponent, { panelClass: "cce-dialog-container", data: message });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.notServ.deleteById(notification).subscribe(response => {
          this.refreshGrid();
          this.messageService.showMessage(response.message);
        }, error => {
          this.spinner.hide();
          console.log(error.error);
        });
      }
    });
  }

}
