//-----------------------------------------------------------------------------
// Framework imports
//-----------------------------------------------------------------------------
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';

//-----------------------------------------------------------------------------
// Other modules in our application
//-----------------------------------------------------------------------------
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';

//-----------------------------------------------------------------------------
// Components, etc. declared in this module
//-----------------------------------------------------------------------------
import { AppComponent } from '@app/components/app/app.component';
import { LoginComponent } from '@app/components/login/login.component';
import { ResetPassWindowComponent } from '@app/components/reset-pass-window/reset-pass-window.component';
import { ChangePassWindowComponent } from '@app/components/change-pass-window/change-pass-window.component';
import { ChangePassComponent } from './../components/change-pass/change-pass.component';
import { HomeComponent } from '@app/components/home/home.component';
import { HeaderComponent } from '@app/components/header/header.component';
import { MainComponent } from '@app/components/main/main.component';
import { ParamConfigComponent } from '@app/components/param-config/param-config.component';
import { FileStorageComponent } from '@app/components/file-storage/file-storage.component';
import { AboutComponent } from '@app/components/about/about.component';
import { NotificationShowComponent } from '@app/components/notification-show/notification-show.component';
import { PlaygroundComponent } from '@app/components/playground/playground.component';
import { AuthInterceptor } from '@app/helpers/auth.interceptor';
import { ParamFormComponent } from '@app/components/param-form/param-form.component';
import { CalcModelComponent } from '@app/components/calc-model/calc-model.component';
import { ParamScopeComponent } from '@app/components/param-scope/param-scope.component';
import { CarEquipmentComponent } from '@app/components/car-equipment/car-equipment.component';
import { NewBatchCalculationComponent } from '@app/components/new-batch-calculation/new-batch-calculation.component';
import { NewCompetitorVehicleComponent } from '@app/components/new-competitor-vehicle/new-competitor-vehicle.component';
import { AddScenarioComponent } from '@app/components/add-scenario/add-scenario.component';
import { DialogFrequencyComponent } from '@app/components/dialog-frequency/dialog-frequency.component';
import { AvailableCarsComponent } from '@app/components/available-cars/available-cars.component';
import { SelectedCarsComponent } from '@app/components/selected-cars/selected-cars.component';
import { AvailableScenarioComponent } from '@app/components/available-scenario/available-scenario.component';
import { SelectedScenarioComponent } from '@app/components/selected-scenario/selected-scenario.component';
import { SingleSelectedScenarioComponent } from '@app/components/single-selected-scenario/single-selected-scenario.component';
import { SuccessorsChooseComponent } from '@app/components/successors-choose/successors-choose.component';
import { CalcSheetComponent } from '@app/components/calc-sheet/calc-sheet.component';
import { EquipmentComponent } from '@app/components/equipment/equipment.component';
import { MaintenanceComponent } from '@app/components/maintenance/maintenance.component';
import { CsrDispatchComponent } from '@app/components/csr-dispatch/csr-dispatch.component';
import { CsrImageURLComponent } from '@app/components/csr-image-url/csr-image-url.component';
import { CsrCurrencyComponent } from '@app/components/csr-currency/csr-currency.component';
import { CsrLiteralComponent } from '@app/components/csr-literal/csr-literal.component';
import { CsrRealComponent } from '@app/components/csr-real/csr-real.component';
import { PrDispatchComponent } from '@app/components/pr-dispatch/pr-dispatch.component';
import { PrPercentageComponent } from './../components/pr-percentage/pr-percentage.component';
import { PrCurrencyComponent } from './../components/pr-currency/pr-currency.component';
import { BtnCellRenderer } from '@app/components/btn-cell-renderer/btn-cell-renderer.component';
import { BtnMultiCellRenderer } from '@app/components/btn-multi-cell-renderer/btn-multi-cell-renderer.component';
import { CheckboxCellRenderer } from '@app/components/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { ConfiguredParamsComponent } from '@app/components/configured-params/configured-params.component';
import { CsrTcoChartComponent } from '@app/components/csr-tco-chart/csr-tco-chart.component';
import { CsrPartsSubgridComponent } from '@app/components/csr-parts-subgrid/csr-parts-subgrid.component';
import { CsrPartsSubgridInnerComponent } from '@app/components/csr-parts-subgrid-inner/csr-parts-subgrid-inner.component';
import { CsrDistanceComponent } from '@app/components/csr-distance/csr-distance.component';
import { CsrDistanceRateComponent } from '@app/components/csr-distance-rate/csr-distance-rate.component';
import { MrPartsSubgridComponent } from '@app/components/mr-parts-subgrid/mr-parts-subgrid.component';
import { MrAccordionComponent } from '@app/components/mr-accordion/mr-accordion.component';
import { AcarSpecificationsComponent } from '@app/components/acar-specifications/acar-specifications.component';
import { AcarEquipmentComponent } from '@app/components/acar-equipment/acar-equipment.component';
import { AcarResidualComponent } from '@app/components/acar-residual/acar-residual.component';
import { AcarUtilComponent } from '@app/components/acar-util/acar-util.component';
import { EqrCheckboxesComponent } from '@app/components/eqr-checkboxes/eqr-checkboxes.component';
import { AcarUtilOptionalComponent } from '@app/components/acar-util-optional/acar-util-optional.component';
import { SheetComponent } from '@app/components/sheet/sheet.component';
import { HeaderMainComponent } from '@app/components/header-main/header-main.component';
import { DialogExcelExportComponent } from '@app/components/dialog-excel-export/dialog-excel-export.component';
import { DialogOptionChooseComponent } from './../components/dialog-option-choose/dialog-option-choose.component';
import { DialogCsvExportComponent } from '@app/components/dialog-csv-export/dialog-csv-export.component';
import { DialogPDFExportComponent } from '@app/components/dialog-pdf-export/dialog-pdf-export.component';
import { YesNoMessageComponent } from '@app/components/yes-no-message/yes-no-message.component';
import { SelectedCarsHeader } from '@app/components/selected-cars-header/selected-cars.header';
import { AdminPanelComponent } from '@app/components/admin-panel/admin-panel.component';
import { NotificationPanelComponent } from '@app/components/notification-panel/notification-panel.component';
import { NotificationDialogComponent } from '@app/components/notification-dialog/notification-dialog.component';
import { ScenarioSelectionComponent } from '@app/components/scenario-selection/scenario-selection.component';
import { Constants } from '@app/helpers/Constants';
import { FileShareComponent } from '@app/components/file-share/file-share.component';
import { AdminAddUserComponent } from '@app/components/admin-add-user/admin-add-user.component';
import { AppConfigService } from '@app/services/appconfig.service';
import { ConfirmDialogComponent } from '@app/components/confirm-dialog/confirm-dialog.component';
import { DefaultScenarioComponent } from '@app/components/default-scenario/default-scenario.component';
import { AdminComponent } from '@app/components/admin/admin.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { LogoutConfiramationComponent } from "@app/components/logout-confiramation/logout-confiramation.component";
import { DataAvailabilityComponent } from "@app/components/data-availability/data-availability.component";
import { SecuredImageComponent } from "@app/components/secured-image/secured-image.component";
import { UpdateTriggerComponent } from "@app/components/update-trigger/update-trigger.component";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.loadEnv().then(() => {
      appConfigService.loadMetadata();
      appConfigService.loadFilters();
    });
  }
}

// I change dateformat to en-GB, and when writing in datapicker input get error
export const MY_DATE_FORMATS = {
  parse: { dateInput: 'DD/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ResetPassWindowComponent,
    ChangePassWindowComponent,
    ChangePassComponent,
    HeaderComponent,
    MainComponent,
    ParamConfigComponent,
    FileStorageComponent,
    AboutComponent,
    NotificationShowComponent,
    ParamFormComponent,
    CalcModelComponent,
    ParamScopeComponent,
    CarEquipmentComponent,
    FileShareComponent,
    PlaygroundComponent,
    NewBatchCalculationComponent,
    NewCompetitorVehicleComponent,
    AddScenarioComponent,
    DialogFrequencyComponent,
    DialogExcelExportComponent,
    DialogOptionChooseComponent,
    DialogCsvExportComponent,
    DialogPDFExportComponent,
    YesNoMessageComponent,
    AvailableCarsComponent,
    SelectedCarsComponent,
    AvailableScenarioComponent,
    SelectedScenarioComponent,
    SingleSelectedScenarioComponent,
    SuccessorsChooseComponent,
    CalcSheetComponent,
    EquipmentComponent,
    MaintenanceComponent,
    CsrDispatchComponent,
    PrDispatchComponent,
    CsrImageURLComponent,
    CsrCurrencyComponent,
    CsrLiteralComponent,
    CsrRealComponent,
    PrPercentageComponent,
    PrCurrencyComponent,
    BtnCellRenderer,
    BtnMultiCellRenderer,
    CheckboxCellRenderer,
    CsrTcoChartComponent,
    CsrDistanceComponent,
    CsrDistanceRateComponent,
    MrAccordionComponent,
    MrPartsSubgridComponent,
    CsrPartsSubgridComponent,
    CsrPartsSubgridInnerComponent,
    AcarSpecificationsComponent,
    AcarEquipmentComponent,
    AcarResidualComponent,
    AcarUtilOptionalComponent,
    AcarUtilComponent,
    EqrCheckboxesComponent,
    SheetComponent,
    HeaderMainComponent,
    SelectedCarsHeader,
    ConfiguredParamsComponent,
    AdminPanelComponent,
    NotificationPanelComponent,
    NotificationDialogComponent,
    AdminAddUserComponent,
    ConfirmDialogComponent,
    ScenarioSelectionComponent,
    DefaultScenarioComponent,
    AdminComponent,
    LogoutConfiramationComponent,
    DataAvailabilityComponent,
    SecuredImageComponent,
    UpdateTriggerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([CsrDispatchComponent, AcarSpecificationsComponent, AcarEquipmentComponent, DataAvailabilityComponent,
      AcarResidualComponent, AcarUtilOptionalComponent, AcarUtilComponent, EqrCheckboxesComponent, AvailableCarsComponent,
      MrPartsSubgridComponent, CsrPartsSubgridComponent, CsrPartsSubgridInnerComponent, MrAccordionComponent, HeaderMainComponent, SelectedCarsHeader]),
    ChartsModule, NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    Constants,
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
