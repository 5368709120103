import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { FormsModule} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { Ng5SliderModule } from 'ng5-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMomentDateModule } from '@angular/material-moment-adapter';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatTabsModule,
    FormsModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTreeModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatRippleModule,
    DragDropModule
  ],
  exports: [
      MatCardModule,
      MatIconModule,
      MatInputModule,
      MatButtonModule,
      MatProgressSpinnerModule,
      MatProgressBarModule,
      MatSnackBarModule,
      MatToolbarModule,
      MatSidenavModule,
      MatDialogModule,
      MatDividerModule,
      MatMenuModule,
      MatButtonToggleModule,
      MatTabsModule,
      MatTreeModule,
      MatCheckboxModule,
      MatListModule,
      FormsModule,
      MatTooltipModule,
      MatExpansionModule,
      MatRadioModule,
      MatOptionModule,
      MatSelectModule,
      MatSlideToggleModule,
      MatDatepickerModule,
      MatMomentDateModule,
      MatRippleModule,
      Ng5SliderModule,
      ScrollingModule,
      DragDropModule
  ]
})
export class AppMaterialModule { }
