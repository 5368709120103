import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/lib/interfaces';
// import { isAdmin } from 'src/app//helpers/utils.js';
import { HandleUiMessagesService } from 'src/app/services/handle-ui-messages.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'btn-multi-cell-renderer',
  templateUrl: './btn-multi-cell-renderer.component.html',
  styleUrls: ['./btn-multi-cell-renderer.component.scss']
})
export class BtnMultiCellRenderer implements ICellRendererAngularComp {

  constructor(private messagingService: HandleUiMessagesService, private spinner: NgxSpinnerService) {
  }

  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }

  params: any;
  private parent?: any;

  agInit(params: any): void {
    // console.log(params, "BtnMultiCellRenderer");
    this.params = params;
    this.parent = params.parent;
  }

  // btnViewHandler(event: any) {
  //   this.service!.infoById(this.params.data).subscribe((response) => {
  //     this.params.context.openCamp(response);
  //   }, async error => {
  //     const jsonError = await (new Response(error.error)).json();
  //     this.messagingService.showErrorMessage(jsonError.message);
  //   });
  // }

  btnRemoveHandler() {
    this.parent!.deleteById(this.params.data);
  }


  // isAdmin() {
  //   return isAdmin();
  // }

  ngOnDestroy(): void {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }


}
