import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { getColIndex22ToLength, getModifyState, formatWithSuffixAndPrecision } from '@app/helpers/utils.js';
import { Constants } from '@app/helpers/Constants.ts';
import { metadata } from '@environments/environment'
import { Sheet } from '@app/domain/sheet';
import { sprintf } from 'sprintf-js';
import { AccountService } from '@app/services/account.service';


@Component({
  selector: 'app-csr-real',
  templateUrl: './csr-real.component.html',
  styleUrls: ['./csr-real.component.scss']
})
export class CsrRealComponent implements OnInit {

  @Input() params: ICellRendererParams;

  REF_RENDER = 2;
  OVERLAP_RENDER = 1;
  NO_RENDER = 0;

  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;
  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;

  readonly ACTIVE_TOTAL = Constants.ACTIVE_TOTAL;
  readonly ACTIVE_KM = Constants.ACTIVE_KM;
  readonly ACTIVE_MONTH = Constants.ACTIVE_MONTH;

  readonly UNIT_LITERS = Constants.UNIT_LITERS;
  readonly UNIT_KG = Constants.UNIT_KG;
  readonly METADATA_FIELD_FUEL = Constants.METADATA_FIELD_FUEL;
  readonly FUEL_HYDROGEN = Constants.FUEL_HYDROGEN;
  readonly METADATA_FIELD_TOTAL_FUEL_CONSUMPTION = Constants.METADATA_FIELD_TOTAL_FUEL_CONSUMPTION;
  readonly METADATA_FIELD_FUEL_CONSUMPTION = Constants.METADATA_FIELD_FUEL_CONSUMPTION;
  readonly FUEL_CNG = Constants.FUEL_CNG;

  value: string;
  metadataValue;
  style: string;
  modified: number;
  indexCol: number;
  benchmarkColumnId: number;
  idsCol: number;
  colorSpan: number;
  wrongValue: string;
  render = 0;
  refImg: string;
  prec: number;


  constructor(private accountService: AccountService) { }

  getUnit(field, ctx) {
    if (metadata[field].units == undefined) {
      return '';
    } else if (this.UNIT_LITERS != metadata[field].units) {
      return metadata[field].units;
    } else if ((field === this.METADATA_FIELD_TOTAL_FUEL_CONSUMPTION || field === this.METADATA_FIELD_FUEL_CONSUMPTION)) {
      const path = metadata[this.METADATA_FIELD_FUEL].path[0] + '.editData[\'' + this.idsCol + '\'].origValue';
      const value = ctx.getOldValue(path);
      if (this.FUEL_HYDROGEN === value.trim() || value.trim().includes(this.FUEL_CNG)) {
        return this.UNIT_KG;
      } else {
        return metadata[field].units;
      }
    }
  }

  ngOnInit(): void {
    console.log("show job overlap: ", this.accountService.showJobOverlap());
    const sheet: Sheet = this.params.context.getDataSheet();
    this.indexCol = getColIndex22ToLength(this.params.colDef.field);
    this.idsCol = sheet.columnIds[this.indexCol];
    const ctx = this.params.context;
    const field = this.params.data.field;
    const editData = this.params.data.editData;
    const units = this.getUnit(field, ctx);
    this.style = metadata[field].style;
    this.prec = metadata[field].prec;
    this.metadataValue = editData[this.idsCol].metadata;

    this.benchmarkColumnId = ctx.getBenchMarkColumn();


    if (this.params.data.values[this.idsCol] === null || this.params.data.values[this.idsCol] === undefined ||
      this.params.data.values[this.idsCol] === '') {
      if (metadata[field].insuffDataWhenEmpty === true) {
        this.value = 'Insufficient data';
      } else {
        this.value = '';
      }
      if (this.params.data.values[this.idsCol] !== '') {
        this.modified = this.NO_MODIFIED_CELL;
        return;
      }
    }

    if (this.metadataValue !== undefined && this.metadataValue !== null) {
      if (this.accountService.showJobOverlap() && this.metadataValue.overlap !== undefined && !Number.isNaN(Number(this.metadataValue.overlap)) &&
        this.metadataValue.overlap > this.params.data.values[this.idsCol]) {
        this.render = this.OVERLAP_RENDER;
      } else if (this.metadataValue !== undefined && this.metadataValue !== null && (this.metadataValue.ref === 'REFA' || this.metadataValue.ref === 'A' || this.metadataValue.ref === 'REFB' || this.metadataValue.ref === 'B')) {
        this.render = this.REF_RENDER;
        this.refImg = '../../assets/' + this.metadataValue.ref + '.png';
      }
    }

    if (Number.isNaN(Number(this.params.data.values[this.idsCol]))) {
      this.params.data.editData[this.idsCol].error = true;
      editData[this.idsCol].modified = false;
      this.wrongValue = this.params.data.values[this.idsCol];
      const path = metadata[field].path[0] + '.editData[\'' + this.idsCol + '\'].origValue';
      const value = ctx.getOldValue(path);
      this.params.data.values[this.idsCol] = value;
      this.value = value;
    }
    else {
      this.params.data.editData[this.idsCol].error = false;
    }
    this.modified = getModifyState(editData[this.idsCol].modified, editData[this.idsCol].error);

    if (this.modified) {
      this.colorSpan = this.TEXT_COLOR_BLUE;
    }


    if (this.benchmarkColumnId >= 0 && this.benchmarkColumnId !== this.idsCol) {
      let bmValue = this.params.data.bmValues[this.idsCol];
      if (bmValue.value === 'identical') {
        this.value = bmValue.value;
      } else {
        if (bmValue.value > 0) {
          this.value = '+' + formatWithSuffixAndPrecision(bmValue.value, units, this.prec);
        } else {
          this.value = formatWithSuffixAndPrecision(bmValue.value, units, this.prec);
        }
        if (metadata[field].baseUnit && sheet.viewingMode === this.ACTIVE_MONTH) {
          this.value += ' / mo';
        } else if (metadata[field].baseUnit && sheet.viewingMode === this.ACTIVE_KM) {
          this.value += ' / ' + this.params.context.distanceUnit;
        }
      }
      if (!!bmValue.color) {
        this.colorSpan = bmValue.color;
      }

    } else {
      if (metadata[field].baseUnit && sheet.viewingMode !== this.ACTIVE_TOTAL) {
        this.value = this.getScenarioBaseVal(sheet, sheet.viewingMode, units);
      } else {
        this.value = formatWithSuffixAndPrecision(this.params.data.values[this.idsCol], units, this.prec);
      }
    }

  }

  setOldValue() {
    this.params.context.setOldValueMainTable(this.params, this.idsCol, this.benchmarkColumnId);
  }

  getToolTip(render) {
    switch (render) {
      case this.OVERLAP_RENDER: {
        let unadj = this.metadataValue.overlap;
        let save = unadj - this.params.data.values[this.idsCol];
        return sprintf('Total unadjusted time: %.2f \n Total saving time: %.2f', unadj, save);
      }
      case this.NO_RENDER: {
        return 'Invalid value: ' + this.wrongValue;
      }
    }
    return 'Invalid value: ' + this.wrongValue;
  }

  getScenarioBaseVal(sheet: Sheet, viewingMode, units): string {
    let retval = '';
    for (const element of sheet.dataTCO[1].children) {
      if (element.field === 'duration' && viewingMode === this.ACTIVE_MONTH) {
        retval = formatWithSuffixAndPrecision(this.params.data.values[this.idsCol] / element.values[this.idsCol], units + ' / mo', this.prec);
        break;
      }
      if (element.field === 'distance' && viewingMode === this.ACTIVE_KM) {
        retval = formatWithSuffixAndPrecision(this.params.data.values[this.idsCol] / element.values[this.idsCol], units +
          ' / ' + this.params.context.distanceUnit, this.prec);
        break;
      }
    }
    if (retval === '') {
      return formatWithSuffixAndPrecision(this.params.data.values[this.idsCol], units, this.prec);
    } else {
      return retval;
    }
  }

}
