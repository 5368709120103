import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/lib/interfaces';
// import { isAdmin } from 'src/app//helpers/utils.js';
@Component({
  selector: 'checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  styleUrls: ['./checkbox-cell-renderer.component.scss']
})
export class CheckboxCellRenderer implements ICellRendererAngularComp {

  constructor() {
  }

  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }

  isChecked = false;

  agInit(params: any): void {
    this.isChecked = params.value;
  }

  ngOnDestroy(): void {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }


}
