<div class="admin" style="height: 70vh">
  <mat-card style="height: 70vh">
    <mat-card-content style=" height: 100%;width: 100%">
      <div class="header">{{ title }}</div>
      <div class="field-body" style="height: calc(70vh - 100px); overflow:auto">
        <form [formGroup]="notificationForm" novalidate>

          <div class="up-left">

            <div class="check-container">
              <mat-checkbox [(ngModel)]="notification.priority" formControlName="priority"></mat-checkbox>
              <span class="check-label">IS PRIORITY</span>
            </div>

            <div class="dp-container cce-row">

              <div class="cce-col">
                <div>
                  <span class="label">VALID FROM</span><span class="required"> *</span>
                </div>
                <mat-form-field class="data-picker-field" appearance="fill">
                  <mat-label>Select date</mat-label>
                  <input matInput formControlName="validFrom" [matDatepicker]="picker"
                    [(ngModel)]="notification.validFrom">
                  <mat-datepicker-toggle class="date-picker" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="cce-col">
                <div>
                  <span class="label">VALID TO</span><span class="required"> *</span>
                </div>
                <mat-form-field appearance="fill" class="data-picker-field">
                  <mat-label>Select date</mat-label>
                  <input matInput formControlName="validTo" [matDatepicker]="pickerTo"
                    [(ngModel)]="notification.validTo">
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> value="{{notification.validFrom}}" -->
                  <mat-datepicker-toggle class="date-picker" matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
              </div>

            </div>
            <div style="margin-top: -15px; margin-bottom: 10px"
              *ngIf="notificationForm.errors?.dateRangeInvalid && notificationForm.touched">
              <mat-error class="error-message">VALID TO must be greater than or equal to VALID FROM.</mat-error>
            </div>

            <div>
              <div>
                <span class="label"
                  [ngClass]="{'form-unsubmitted': headerPristine(), 'form-validate': headerInvalid()}">HEADER</span><span
                  class="required"> *</span>
              </div>
              <mat-form-field class="header-field" style="width:100%;" appearance="fill">
                <!-- <mat-label>HEADER</mat-label> -->
                <input matInput formControlName="header" [(ngModel)]="notification.header" style="width:100%;">
              </mat-form-field>
            </div>
          </div>

          <div class="ta-container">
            <div>
              <span class="label"
                [ngClass]="{'form-unsubmitted': messagePristine(), 'form-validate': messageInvalid()}">MESSAGE</span><span
                class="required"> *</span>
            </div>
            <div>
              <mat-form-field appearance="fill" style="width:100%;">
                <!-- <mat-label>MESSAGE</mat-label> -->
                <textarea style="resize: none; height: calc(70vh - 470px)" formControlName="message"
                  [(ngModel)]="notification.message" class="example-form" matInput></textarea>
              </mat-form-field>
            </div>

          </div>

        </form>

        <div class="button_container">
          <div style="flex:1">
            <a class="preview-button" [class.disabled]="notificationForm.invalid"
              (click)="notificationForm.invalid ? $event.preventDefault():onClickPreview()">Preview</a>
          </div>
          <button class="button_save" type="submit" (click)="onSubmit()" mat-button>SAVE</button>
          <button class="button_close" type="button" (click)="onClose()" mat-dialog-close mat-button>CLOSE</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
