<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
<div class="head cce-row">
  <span>AUTOVISTA NEWS</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>

<div class="content cce-col">

  <div>
    <span style="color:black; font-weight: bold;">{{notification.header}}</span>
    <h4 style="color:black">{{ notification.dateCreated ? this.pipe.transform(notification.dateCreated, 'dd/MM/yyyy') :
      '' }}</h4>
  </div>

  <iframe class="frame" [sandbox]="'allow-styles'" [srcdoc]="safeMessage" width="100%">
  </iframe>
  <div class="confirm-button">
    <button class="button_close" type="button" (click)="onClose()" mat-dialog-close mat-button>CONFIRM</button>
  </div>
</div>
