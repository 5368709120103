import { Component, OnInit, Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { sprintf } from '../../../../node_modules/sprintf-js';
import { getColIndex22ToLength } from '@app/helpers/utils.js';


@Component({
  selector: 'app-csr-tco-chart',
  templateUrl: './csr-tco-chart.component.html',
  styleUrls: ['./csr-tco-chart.component.scss']
})
export class CsrTcoChartComponent implements OnInit {

  @Input() params: ICellRendererParams;
  public rendered: boolean = false;
  public show = true;
  chartType: ChartType = 'doughnut';
  datasets: ChartDataSets[] = [
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      backgroundColor: ['rgb(228, 135, 1)', 'rgb(117, 176, 0)', 'rgb(255, 213, 18)', 'rgb(228, 248, 160)',
        'rgb(240, 94, 39)', 'rgb(102, 147, 176)', 'rgb(27, 149, 217)', 'rgb(202, 202, 158)', 'rgb(134, 209, 228)']
    },
  ];

  labels: Label[] = ['Depreciation', 'Util.', 'Tyres', 'Wear', 'Service', 'Fuel', 'Insurance', 'Acq.', 'Financing'];

  public options: ChartOptions;

  constructor() {
    var that = this;
    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: 'left',
        align: 'start',
        labels: {
          fontSize: 10,
          boxWidth: 15
        }
      },
      animation: {
        onComplete: function () {
          that.rendered = true;
        }
      },
      onResize: function (col) {
        let width = col['width'];
        if (width < 300) {
          this['legend']['position'] = 'bottom';
        } else if (width >= 300) {
          this['legend']['position'] = 'left';
        }
      }
    };

  }


  ngOnInit(): void {
    this.rendered = false;
    const indexCol = getColIndex22ToLength(this.params.colDef.field);
    const idsCol = this.params.context.getDataSheet().columnIds[indexCol];
    const tmp = this.params.data.values[idsCol];
    var tyres = tmp.tyres === undefined || tmp.tyres === null ? 0 : tmp.tyres;
    var insurance = tmp.insurance === undefined || tmp.insurance === null ? 0 : tmp.insurance;
    var utTaxes = tmp.utilisationTaxes === undefined || tmp.utilisationTaxes === null ? 0 : tmp.utilisationTaxes;
    var service = tmp.service === undefined || tmp.service === null ? 0 : tmp.service;
    var fuel = tmp.fuel === undefined || tmp.fuel === null ? 0 : tmp.fuel;
    var wear = tmp.wear === undefined || tmp.wear === null ? 0 : tmp.wear;
    var depr = tmp.deprecation === undefined || tmp.deprecation === null ? 0 : tmp.deprecation;
    var aqTaxes = tmp.acquisitionTaxes === undefined || tmp.acquisitionTaxes === null ? 0 : tmp.acquisitionTaxes;
    var finance = tmp.finance === undefined || tmp.finance === null ? 0 : tmp.finance;
    var total = tyres + insurance + utTaxes + service + fuel + wear + depr + finance + finance;

    if (total === 0) {
      this.show = false;
      return;
    }

    this.datasets[0].data[0] = sprintf("%.1f", depr);
    this.labels[0] = sprintf("Depreciation: %.1f%%", 100 * depr / total);

    this.datasets[0].data[1] = sprintf("%.1f", utTaxes);
    this.labels[1] = sprintf("Utilisation Taxes: %.1f%%", 100 * utTaxes / total);

    this.datasets[0].data[2] = sprintf("%.1f", tyres);
    this.labels[2] = sprintf("Tyres: %.1f%%", 100 * tyres / total);

    this.datasets[0].data[3] = sprintf("%.1f", wear);
    this.labels[3] = sprintf("Wear: %.1f%%", 100 * wear / total);

    this.datasets[0].data[4] = sprintf("%.1f", service);
    this.labels[4] = sprintf("Service: %.1f%%", 100 * service / total);

    this.datasets[0].data[5] = sprintf("%.1f", fuel);
    this.labels[5] = sprintf("Fuel: %.1f%%", 100 * fuel / total);

    this.datasets[0].data[6] = sprintf("%.1f", insurance);
    this.labels[6] = sprintf("Insurance: %.1f%%", 100 * insurance / total);

    this.datasets[0].data[7] = sprintf("%.1f", aqTaxes);
    this.labels[7] = sprintf("Acquisition Taxes: %.1f%%", 100 * aqTaxes / total);

    this.datasets[0].data[8] = sprintf("%.1f", finance);
    this.labels[8] = sprintf("Financing: %.1f%%", 100 * finance / total);

  }
}
