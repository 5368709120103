<div>
  <div id="cars-page" *ngIf="(numberPage === 0)">
    <div class="cce-header-nbc-vehicle cce-row">
      <span style="margin:auto" id="bigTitileText">COMPETITORS SEARCH</span>
    </div>
    <div class="cce-filters-new-batch">
      <div class="grid-info">
        <div class="cce-header-custom-filters">
          <div style="width: 20%;">
            <span class="cce-label" style="font-size: 16px; padding: 7px 0 0 0;cursor: pointer;">COUNTRY:
              {{baseVehicle.market}}</span>
          </div>
        </div>

        <ng-container>
          <div style="padding: 0px 0 5px 0;">
            <div class="horizontalDelimiter"></div>
          </div>
          <div class="cce-row">
            <div class="cce-dd-list">
              <div *ngFor="let elem of selectedFilters;" class="filter">
                <div class="cce-col">
                  <div class="filterLabel">
                    <span>{{ filtersMETA[elem].label }}</span>
                  </div>
                  <div *ngIf="isRange(elem)" class="cce-col cce-filter-slider">
                    <ng5-slider [(value)]="filterTemplate[elem].from" [(highValue)]="filterTemplate[elem].to"
                      [options]="filterTemplate[elem].options" (userChange)="customFilterChange(elem)">
                    </ng5-slider>
                  </div>
                  <div *ngIf="isNaN(elem)" class="cce-col cce-filter-list">
                    <span>NaN</span>
                  </div>
                  <div *ngIf="isList(elem)" class="cce-col cce-filter-list">
                    <span *ngFor="let item of getFilterValuesAsArray(elem)" [class]="getStyle(elem, item)"
                      (click)="toggleSelection(elem, item)">&nbsp;{{item}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <span class="cce-spacer"></span>
          </div>
        </ng-container>
        <div style="position: relative; padding: 10px; text-align: center;">
          <span>SHOWING {{totalFilteredItems}} VEHICLES</span>
        </div>
      </div>
    </div>
    <div>
      <div class="cce-row">
        <app-available-cars [currencyID]="currencyID" #availableCarsGrid [style.width]="(display==='none')?'100%':'65%'"
          [parent]="this" [firstCSPage]="firstCSPage" [selectedIds]="selectedAvailableNodes">
        </app-available-cars>
        <app-selected-cars #selectedCarsGrid [style.width]="(display==='none')?'0%':'35%'" [style.display]="display"
          [parent]="this" [selectedCars]="selCarRows">
        </app-selected-cars>
      </div>
    </div>
  </div>
</div>
