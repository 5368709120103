import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { calcPriceByRate, formatCurrency, getColIndex22ToLength, getModifyState, } from '@app/helpers/utils.js';
import { Constants } from '@app/helpers/Constants.ts';
import { metadata } from '@environments/environment'
import { Sheet } from '@app/domain/sheet';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-csr-currency',
  templateUrl: './csr-currency.component.html',
  styleUrls: ['./csr-currency.component.scss']
})
export class CsrCurrencyComponent implements OnInit {

  @Input() params: ICellRendererParams;
  readonly jp = require('jsonpath');
  readonly mapFieldNameTaxesInfo = {
    evIncentive: "ev",
    registrationTax: "registration",
    roadTax: "road",
    ownershipTax: "ownership",
  };

  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;

  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;

  readonly ACTIVE_TOTAL = Constants.ACTIVE_TOTAL;
  readonly ACTIVE_KM = Constants.ACTIVE_KM;
  readonly ACTIVE_MONTH = Constants.ACTIVE_MONTH;


  value: string;
  style: string;
  modified: number;
  indexCol: number;
  idsCol: number;
  benchmarkColumnId: number;
  colorSpan: number;
  wrongValue: string;
  isNotCarSpec = false;
  field: string;
  sheet: Sheet;
  showTaxInfo = false;
  market;
  marketPath;

  constructor() {
  }

  ngOnInit(): void {
    this.sheet = this.params.context.getDataSheet();
    this.indexCol = getColIndex22ToLength(this.params.colDef.field);
    this.idsCol = this.sheet.columnIds[this.indexCol];
    const ctx = this.params.context;
    const field = this.params.data.field;
    const editData = this.params.data.editData;
    this.style = metadata[field].style;
    this.field = this.params.data.field;
    if (!!environment.taxesInformation) {
      this.showTaxInfo = this.isShowTaxInfo();
    }

    this.isNotCarSpec = this.params.node.parent.data === undefined ||
      (this.params.node.parent.data !== undefined && this.params.node.parent.data.field !== 'carSpecs');

    this.benchmarkColumnId = ctx.getBenchMarkColumn();
    if (this.params.data.values[this.idsCol] === null || this.params.data.values[this.idsCol] === undefined ||
      this.params.data.values[this.idsCol] === '') {
      if (metadata[field].insuffDataWhenEmpty === true) {
        this.value = 'Insufficient data';
      } else {
        this.value = '';
      }
      if (this.params.data.values[this.idsCol] !== '') {
        this.modified = this.NO_MODIFIED_CELL;
        return;
      }
    }

    if (Number.isNaN(Number(this.params.data.values[this.idsCol]))) {
      this.params.data.editData[this.idsCol].error = true;
      editData[this.idsCol].modified = false;
      this.value = this.params.data.values[this.idsCol];
      this.wrongValue = this.params.data.values[this.idsCol];
      let path = metadata[field].path[0] + '.editData[\'' + this.idsCol + '\'].origValue';
      const value = ctx.getOldValue(path);
      path = metadata[field].path[0] + '.editData[\'' + this.idsCol + '\'].modified';
      ctx.setValue(path, true);
      this.params.data.values[this.idsCol] = value;
      this.value = value;
    }
    else {
      this.params.data.editData[this.idsCol].error = false;
    }

    this.modified = getModifyState(editData[this.idsCol].modified, editData[this.idsCol].error);

    if (this.modified) {
      this.colorSpan = this.TEXT_COLOR_BLUE;

    }

    if (this.benchmarkColumnId >= 0 && this.benchmarkColumnId !== this.idsCol) {
      let bmValue = this.params.data.bmValues[this.idsCol];
      if (bmValue.value === 'identical') {
        this.value = bmValue.value;
      } else {
        if (bmValue.value > 0) {
          this.value = '+' + formatCurrency(bmValue.value, this.params.context.currencyUnit);
        } else {
          this.value = formatCurrency(bmValue.value, this.params.context.currencyUnit);
        }
        if (metadata[field].baseUnit && this.sheet.viewingMode === this.ACTIVE_MONTH && this.isNotCarSpec) {
          this.value += ' / mo';
        } else if (metadata[field].baseUnit && this.sheet.viewingMode === this.ACTIVE_KM && this.isNotCarSpec) {
          this.value += ' / ' + this.params.context.distanceUnit;
        }
      }
      if (!!bmValue.color) {
        this.colorSpan = bmValue.color;
      }
    }
    else {
      if (metadata[field].baseUnit && this.sheet.viewingMode !== this.ACTIVE_TOTAL && this.isNotCarSpec) {
        this.value = this.getScenarioBaseVal(this.sheet, this.sheet.viewingMode);
      } else {
        // if (this.field == 'optionalEquipment') {
        //   this.value = formatCurrency(this.calcPriceByRate(this.params.data.values[this.idsCol]), this.params.context.currencyUnit);
        // } else {
        this.value = formatCurrency(this.params.data.values[this.idsCol], this.params.context.currencyUnit);
        // }
      }
    }

  }

  isTaxesField() {
    return this.field == 'evIncentive' || this.field == 'registrationTax' || this.field == 'ownershipTax' || this.field == 'roadTax';
  }

  isShowTaxInfo() {
    if (this.params.data.values[this.idsCol] == 0 || !this.isTaxesField()) {
      return false;
    }
    this.marketPath = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'market\')]' + '.values[\'' + this.idsCol + '\']';
    this.market = this.jp.query(this.sheet.dataTCO, this.marketPath)[0];
    return !!environment.taxesInformation && !!this.mapFieldNameTaxesInfo[this.field] && !!environment.taxesInformation[this.mapFieldNameTaxesInfo[this.field]]
      && !!environment.taxesInformation[this.mapFieldNameTaxesInfo[this.field]][this.market];
  }

  setOldValue() {
    this.params.context.setOldValueMainTable(this.params, this.idsCol, this.benchmarkColumnId);
  }

  getTaxesToolTip() {
    return environment.taxesInformation[this.mapFieldNameTaxesInfo[this.field]][this.market];
  }

  getToolTip() {
    return 'Invalid value: ' + this.wrongValue;
  }

  getScenarioBaseVal(sheet: Sheet, viewingMode): string {
    let retval = '';
    let price;
    for (const element of sheet.dataTCO[1].children) {
      if (element.field === 'duration' && viewingMode === this.ACTIVE_MONTH) {
        price = this.params.data.values[this.idsCol] / element.values[this.idsCol];
        retval = formatCurrency(price, this.params.context.currencyUnit) + ' / mo';
        break;
      }
      if (element.field === 'distance' && viewingMode === this.ACTIVE_KM) {
        price = this.params.data.values[this.idsCol] / element.values[this.idsCol];
        retval = formatCurrency(price, this.params.context.currencyUnit) +
          ' / ' + this.params.context.distanceUnit;
        break;
      }
    }

    if (retval === '') {
      // if (this.field == 'optionalEquipment') {
      //   formatCurrency(this.calcPriceByRate(this.params.data.values[this.idsCol]), this.params.context.currencyUnit)
      // } else {
      formatCurrency(this.params.data.values[this.idsCol], this.params.context.currencyUnit)
      // }
      return;
    } else {
      return retval;
    }
  }

  calcPriceByRate(value) {
    if (this.field == 'netListPrice') {
      return calcPriceByRate(value, 1);
    }
    return calcPriceByRate(value, this.sheet.exchangeRates.get(this.idsCol));
  }

}
