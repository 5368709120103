import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '@app/components/about/about.component';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { AdminPanelComponent } from '../admin-panel/admin-panel.component';
import { LogoutConfiramationComponent } from '../logout-confiramation/logout-confiramation.component';
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component';
import { NotificationService } from '@app/services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  NS_NO_MESSAGE = "N";
  NS_STANDART_MESSAGE = "S";
  NS_PRIOR_MESSAGE = "P";

  notifyStatus = this.NS_NO_MESSAGE;

  userName: String;
  companyName: String;
  isAdmin: boolean = false;
  @Input() hasAdminButton: ICellRendererParams;

  constructor(
    private accountService: AccountService,
    private aboutDialog: MatDialog,
    private adminDialog: MatDialog,
    private router: Router,
    private logoutAthentication: MatDialog, private ns: NotificationService
  ) { }

  ngOnInit(): void {
    this.checkNotifiations();
    this.userName = this.accountService.subjectName;
    this.companyName = this.accountService.companyName;
    this.isAdmin = this.accountService.hasRole("cceClientAdmin");
  }

  logout() {

    const dialogRef = this.logoutAthentication.open(LogoutConfiramationComponent
      , {
        data: {
          parent: this
        }
      });

  }

  openHelp() {
    const dialogRef = this.aboutDialog.open(AboutComponent, {
      closeOnNavigation: false, width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container"
    });
  }


  openAbout() {
    const dialogRef = this.aboutDialog.open(AboutComponent, {

    });
  }

  openNotifications() {
    const dialogRef = this.adminDialog.open(NotificationPanelComponent, {
      width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container"
    });
    // this.router.navigate(['/admin']);
  }

  openAdmin() {

    const dialogRef = this.adminDialog.open(AdminPanelComponent, {
      width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container"
    });

    // this.router.navigate(['/admin']);
  }

  checkNotifiations() {
    this.ns.getNotificationsStatus().subscribe(news => {
      // console.log(news, "news");
      this.notifyStatus = news.status;
    });
  }

}
