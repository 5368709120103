<div class="button-row">
  <div class="flex-container">

    <!-- <div [hidden]="!params.hasView" class="button-container">
      <button (click)="btnViewHandler($event)" class="material-icons ucb-grid-button"
        [matTooltip]="getToolTip(VISIBILLITY_BUTTON)">visibility</button>
    </div> -->

    <div [hidden]="!params.hasDelete" class="button-container">
      <button (click)="btnRemoveHandler()" class="material-icons grid-button" matTooltip="Remove"
        tabIndex="-1">delete</button><!--tabIndex="-1" because after login when open dialog first button is focused-->
    </div>
  </div>
</div>
