<div class="ag-cell-label-container bg-blue" role="presentation">
  <!-- <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span> -->
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <!-- <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span> -->

    <div [ngStyle]="{'display' : menu ?  'block': 'none'}">
      <button mat-button [matMenuTriggerFor]="newMenu">
        <mat-icon [ngClass]="dotsColor()">more_vert</mat-icon>
      </button>
    </div>

    <div class="cce-row" style="flex: 1;overflow: inherit;">
      <img class="img-cell-rend pderr-cell-rend" *ngIf="hasAlert()" src='../../assets/alert.png' />
      <span style="overflow: visible;max-height: 30px;"
        [ngClass]="menu ?  'cce-header-sheet-vehicle-col': 'cce-header-sheet-vehicle-col-first'">{{name}}</span>
    </div>

    <mat-menu #newMenu="matMenu">
      <div *ngIf="typeHC===HEADER_COMPONENT_TCO">
        <button class="menuHeaderMain" mat-menu-item (click)="removeCar()">REMOVE</button>
        <button class="menuHeaderMain" mat-menu-item (click)="addCompetitor()">ADD COMPETITOR</button>
        <button class="menuHeaderMain" mat-menu-item (click)="newScenario()">NEW SCENARIO</button>
        <button class="menuHeaderMain" mat-menu-item>
          <mat-checkbox (change)="onToggleBenchmark()" [checked]="isBMColumn()">BENCHMARK</mat-checkbox>
        </button>
      </div>
      <div *ngIf="typeHC===HEADER_COMPONENT_EQUIPMENT">
        <button class="menuHeaderMain" mat-menu-item (click)="removeCar()">REMOVE</button>
        <button class="menuHeaderMain" mat-menu-item (click)="openCarEquipment()">EQUALISE CONFIGURATION</button>
      </div>
      <div *ngIf="typeHC===HEADER_COMPONENT_MAINTENANCE">
        <button class="menuHeaderMain" mat-menu-item (click)="removeCar()">REMOVE</button>
      </div>
    </mat-menu>



    <!-- <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span> -->
  </div>
</div>
