import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { NotificationShowComponent } from '../notification-show/notification-show.component';
import * as lodash from 'lodash'; //copy array
import { NotificationService } from '@app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { dateRangeValidator } from '@app/helpers/date-range.validator'; // Adjust the path as needed
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  isOldNotification = true;
  notification = {
    priority: false,
    dateCreated: "",
    validFrom: new Date(),
    validTo: new Date(),
    header: "",
    message: ""
  };
  pipe = new DatePipe('en-US');
  submitCheck: boolean = false;

  notificationForm: FormGroup;
  _id: any;
  title = "NEW NOTIFICATION";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notServ: NotificationService, private messageService: HandleUiMessagesService, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NotificationDialogComponent>, public dialogPreview: MatDialog, private spinner: NgxSpinnerService,) {

    if (!!data && !!data.notification && !!data.notification._id) {
      this.title = "EDIT NOTIFICATION";
      // this.notification = lodash.cloneDeep(data.notification);
      Object.assign(this.notification, data.notification);
      //have to be with new Date to working initial value of datapicker
      this.notification.validFrom = new Date(data.notification.validFrom);
      this.notification.validTo = new Date(data.notification.validTo);
    } else {
      this.notification.validFrom.setHours(0, 0, 0, 0);
      this.notification.validTo.setHours(0, 0, 0, 0);
      this.isOldNotification = false;
    }

  }


  ngOnInit(): void {
    this.notificationForm = this.formBuilder.group({
      priority: [null, Validators.compose([])],
      validFrom: [null, Validators.compose([Validators.required
        // , Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
      ])],
      validTo: [null, Validators.compose([Validators.required
        // , Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
      ])],
      header: [null, Validators.compose([Validators.required, Validators.minLength(1)])],
      message: [null, Validators.compose([Validators.required, Validators.minLength(1)])]
    },
      { validators: dateRangeValidator });

  }

  onClickPreview() {
    this.dialogPreview.open(NotificationShowComponent, {
      closeOnNavigation: false, width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container", data: this.notification
    }).afterClosed().subscribe(response => {
      // console.log(response, "!!this._id");
      if (!!response && !!response._id) {
        this.notServ.readNotification(response);
      }
    });
  }

  onSubmit() {
    this.firstSubmit();

    if (this.notificationForm.invalid) {
      return;
    }
    this.spinner.show();
    this.notServ.save(this.notification).subscribe(response => {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.messageService.showMessage("The notification was successfully saved!")
    }, error => {
      this.spinner.hide();
      this.messageService.showMessage(error.error);
    });
  }

  onClose() {
    this.dialogRef.close(false);
  }


  firstSubmit() {
    this.submitCheck = true;
  }

  headerPristine(): boolean {
    return this.notificationForm.controls.header.pristine && !this.submitCheck;
  }
  messagePristine(): boolean {
    return this.notificationForm.controls.message.pristine && !this.submitCheck;
  }

  headerInvalid(): boolean {
    return this.notificationForm.controls.header.invalid && this.submitCheck;
  }
  messageInvalid(): boolean {
    return this.notificationForm.controls.message.invalid && this.submitCheck;
  }

}
