<div id="header-topnav">
    <img src="/assets/CCE.png">
    <span class="cce-spacer"></span>
    <span class="header-span"><a class="header-link" href="https://autovistagroup.com/copyright-notices" target="_blank">Copyright & Use Notices</a></span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>
    <span class="header-span">{{userName}}</span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>
    <span class="header-span">{{companyName}}</span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>

    <a *ngIf="isAdmin" class="header-link" (click)="openNotifications()">
      <mat-icon *ngIf="notifyStatus == NS_NO_MESSAGE" mat-icon class="header-icon" title="Notification">
        <img src="../../assets/disabled_bell.png" alt="disabled-bell"></mat-icon>
      <mat-icon *ngIf="notifyStatus == NS_STANDART_MESSAGE" mat-icon class="header-icon" title="Notification">
        <img src="../../assets/standard_bell.png" alt="standard-bell"></mat-icon>
      <mat-icon *ngIf="notifyStatus == NS_PRIOR_MESSAGE" mat-icon class="header-icon" title="Notification">
        <img src="../../assets/priority_bell.png" alt="priority-bell"></mat-icon>
    </a>

    <a class="header-link" (click)="openAdmin()" [style.display]="isAdmin && hasAdminButton ? 'block' : 'none'">
        <mat-icon class="header-icon" title="Administration">supervised_user_circle</mat-icon>
    </a>
    <!-- <a class="header-link" (click)="openAbout()">
        <mat-icon class="header-icon" title="About">info_outline</mat-icon>
    </a> -->
  <a class="header-link" (click)="openHelp()">
    <mat-icon class="header-icon" title="Help">help_outline</mat-icon>
  </a>

  <a *ngIf="!isAdmin" class="header-link" (click)="openNotifications()">
    <mat-icon *ngIf="notifyStatus == NS_NO_MESSAGE" mat-icon class="header-icon" title="Notification">
      <img src="../../assets/disabled_bell.png" alt="disabled-bell"></mat-icon>
    <mat-icon *ngIf="notifyStatus == NS_STANDART_MESSAGE" mat-icon class="header-icon" title="Notification">
      <img src="../../assets/standard_bell.png" alt="standard-bell"></mat-icon>
    <mat-icon *ngIf="notifyStatus == NS_PRIOR_MESSAGE" mat-icon class="header-icon" title="Notification">
      <img src="../../assets/priority_bell.png" alt="priority-bell"></mat-icon>
  </a>

  <mat-divider class="header-div" [vertical]="true"></mat-divider>

    <a class="header-link" (click)="logout()">
        <mat-icon class="header-icon" title="Logout">power_settings_new</mat-icon>
    </a>
</div>
