import { CarsSpecFilters } from './../../domain/CarsSpecFilters';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { Component, ViewChild, Input, AfterViewInit, destroyPlatform } from '@angular/core';
import { CarSpecService } from '@app/services/car-spec.service';
import { formatCurrency, formatWithSuffixAndPrecision } from '@app/helpers/utils.js';
import { NewBatchCalculationComponent } from '../new-batch-calculation/new-batch-calculation.component';
import { GridOptions, IDatasource, RowDoubleClickedEvent, SelectionChangedEvent, BodyScrollEvent, IGetRowsParams } from 'ag-grid-community';
import { SorcService } from '@app/services/sorc.service';
import { DataAvailabilityComponent } from '../data-availability/data-availability.component';
import { FilterComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParamState } from '../param-form/param-form.component';

@Component({
  selector: 'app-available-cars',
  templateUrl: './available-cars.component.html',
  styleUrls: ['./available-cars.component.scss']
})
export class AvailableCarsComponent implements AfterViewInit {

  rowBuffer;
  rowSelection: any;
  rowModelType;
  paginationPageSize;
  cacheOverflowSize;
  maxConcurrentDatasourceRequests;
  infiniteInitialRowCount;
  maxBlocksInCache;
  cacheBlockSize;
  blockLoadDebounceMillis;
  filters: CarsSpecFilters = new CarsSpecFilters();
  @Input() firstCSPage;
  // tslint:disable-next-line: variable-name
  constructor(private _specsService: CarSpecService, private spinner: NgxSpinnerService, private _sorcService: SorcService) {
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 20;
    this.cacheOverflowSize = 2;
    this.cacheBlockSize = 50;
    this.maxConcurrentDatasourceRequests = 1;
    this.blockLoadDebounceMillis = 100;
    this.infiniteInitialRowCount = 100;
    this.maxBlocksInCache = 10;
  }

  replaceDataSource(dataForLoad) {
    // console.log('load data from server called');
    let localData = dataForLoad;
    var dataSource: IDatasource = {
      rowCount: undefined,
      getRows: function (params: IGetRowsParams) {
        if (!!localData) {
          params.successCallback(localData.content, localData.totalElements);
          localData = undefined;
        } else {
          params.context.delayedScroll(params);
        }
      },
      destroy: function () {
        console.log('DS successfuly destroyed.');
      }
    };

    if (this.theGrid != undefined) {
      this.theGrid.api.ensureIndexVisible(0,null);
      this.theGrid.api.setDatasource(dataSource);
      // TODO ISPASOV - find some fix, because grid is not ready and rowNode.data is empty :(
      this.parent.showSelectedCarsInAvailableCarsGrid(2);
    }
  }

  lastFilterTimer = null;
  semaphor = 0;
  sshow(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }

  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    setTimeout(() => {
      if (this.semaphor <= 0) {
        this.semaphor = 0;
        this.spinner.hide();
      }
    },100);
  }
  delayedScroll(params: IGetRowsParams) {
    //console.log("queued with: ", params.startRow, params.endRow);
    if (this.lastFilterTimer != null) {
      clearTimeout(this.lastFilterTimer);
      this.lastFilterTimer = null;
    }
    this.lastFilterTimer = setTimeout(() => {
      //console.log('doing with ', params.startRow, params.endRow);
      //console.log(2);
      this.sshow();
      params.context.filters.setStartRow(params.startRow);
      params.context.filters.setEndRow(params.endRow);
      let sortM = this.getGridSorting();
      // console.log('sort model', sortM);
      if (sortM != null && sortM[0] != null) {
        params.context.filters.setColSort(sortM[0].colId);
        params.context.filters.setSortOrder(sortM[0].sort);
      }
      // optimisation for not needed calls, turned off
      // let fdr = params.context.theGrid.api.getFirstDisplayedRow();
      // let ldr = params.context.theGrid.api.getLastDisplayedRow();
      // if (fdr > params.endRow || ldr < params.startRow) {
      //   console.log('skipped call', fdr, ldr, params.startRow, params.endRow);
      //   params.failCallback();
      //   this.shide();
      // } else {
        params.context.parent.loadPagesCarsSpecs(params.context.filters).subscribe((data: any) => {
          params.successCallback(data.carSpecs.content, data.carSpecs.totalElements);
          this.parent.showSelectedCarsInAvailableCarsGrid(2);
          this.shide();
        });
      // }
    }, 0);
  }

  ngAfterViewInit(): void {
    this.currencySign = getCurrencySignByID(this.currencyID);

    // TO DO maybe this is by back from scenarioselect
    this.gridOptionsSpecs.api.forEachNode(
      node => {
        if (this.selectedIds.includes(node.id)) {
          node.setSelected(true);
        }
      }
    );
  }



  @Input() currencyID;
  currencySign = "";
  self = this;

  @Input() parent: any;
  @ViewChild('theGrid') theGrid;

  currentFilter = {};

  @Input() selectedIds: any = [];

  public columnDefs = [
    { headerName: 'Vehicle', field: 'car', pinned: 'left', width: 315, checkboxSelection: true },
    {
      headerName: 'Alerts', field: 'dataAvailability', comparator: function () { return 0; },
      cellRendererFramework: DataAvailabilityComponent
    },
    //TODO  add col alert
    // {
    //   headerName: '', field: 'srcSpecification', cellRendererFramework: AcarSpecificationsComponent,
    //   width: 34, resizable: false, sortable: false
    // },
    // {
    //   headerName: '', field: 'srcEquipment', cellRendererFramework: AcarEquipmentComponent,
    //   width: 34, resizable: false, sortable: false
    // },
    // {
    //   headerName: '', field: 'srcResidualValue', cellRendererFramework: AcarResidualComponent,
    //   width: 34, resizable: false, sortable: false
    // },
    // {
    //   headerName: '', field: 'srcSWPL', cellRendererFramework: AcarUtilOptionalComponent,
    //   width: 34, resizable: false, sortable: false
    // },
    // {
    //   headerName: '', field: 'srcITFC', cellRendererFramework: AcarUtilComponent,
    //   width: 34, resizable: false, sortable: false
    // },

    {
      headerName: 'Net Price', field: 'netPrice',
      valueFormatter: this.formatCurrency, type: 'rightAligned', width: 120,
    },
    {
      headerName: 'Market', field: 'market', comparator: function () { return 0; },
    },
    {
      headerName: 'Make', field: 'make', comparator: function () { return 0; },
    },
    {
      headerName: 'Model', field: 'model', comparator: function () { return 0; },
    },
    { headerName: 'Trim Level', field: 'trimLevel', comparator: function () { return 0; } },
    { headerName: 'Sale Start', field: 'saleStart', comparator: function () { return 0; } },
    { headerName: 'Sale End', field: 'saleEnd', comparator: function () { return 0; } },
    { headerName: 'Body', field: 'body', comparator: function () { return 0; } },
    { headerName: 'Doors', field: 'doors', type: 'rightAligned', comparator: function () { return 0; } },
    { headerName: 'Seats', field: 'seats', type: 'rightAligned', comparator: function () { return 0; } },
    { headerName: 'Drive', field: 'drive', comparator: function () { return 0; } },
    { headerName: 'Transmission', field: 'transmission', comparator: function () { return 0; } },
    { headerName: 'Speeds', field: 'speeds', type: 'rightAligned', comparator: function () { return 0; } },
    { headerName: 'Fuel', field: 'fuel', comparator: function () { return 0; } },
    {
      headerName: 'Engine Size', field: 'engineSize', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'ccm', 0), type: 'rightAligned'
    },
    { headerName: 'Cylinders', field: 'cylinders', type: 'rightAligned', comparator: function () { return 0; } },
    {
      headerName: 'Horse Power', field: 'horsePower', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'PS', 0), type: 'rightAligned'
    },
    { headerName: 'Acc 0 to 100', field: 'acc0to100', comparator: function () { return 0; } },
    {
      headerName: 'Tax Horse Power', field: 'taxHorsePower', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, '', 2), type: 'rightAligned'
    },
    { headerName: 'Insurance Classification', field: 'insuranceClassification', comparator: function () { return 0; } },
    {
      headerName: 'Fuel Cons.', field: 'fuelConsumption', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'l', 1), type: 'rightAligned'
    },
    {
      headerName: 'CO2', field: 'emissionCO2', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'g', 0), type: 'rightAligned'
    },
    { headerName: 'Emmission std.', field: 'emissionStandard', comparator: function () { return 0; } },
    {
      headerName: 'Kerb Weight', field: 'kerbWeight', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'kg', 0), type: 'rightAligned'
    },
    {
      headerName: 'Gross Vehicle Weight', field: 'grossVehicleWeight', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'kg', 0), type: 'rightAligned'
    },
    {
      headerName: 'Length', field: 'length', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Height', field: 'height', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Width', field: 'width', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Wheelbase', field: 'wheelbase', comparator: function () { return 0; },
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    { headerName: 'Segment', field: 'segment', comparator: function () { return 0; } },
    { headerName: 'ID', field: 'carID', filter: false, comparator: function () { return 0; } },
  ];

  public gridOptionsSpecs: GridOptions = {
    context: this,
    columnDefs: this.columnDefs,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    defaultColDef: {
      width: 100,
      resizable: true,
      sortable: true,
      filter: false
    },
    rowClassRules: {
      // row style expression
      'unfetched': function(params) { return (!params.data || !params.data.carID); },
    },

    onRowDoubleClicked: this.onRowDoubleClicked,
    onSelectionChanged: (params: SelectionChangedEvent) => {
      let rows = params.api.getSelectedRows();
      this.parent.selectedCarsGrid.addSelectedCars(rows);
      this.parent.showSelectedCarsInAvailableCarsGrid(0);
    }
  };

  getGridSorting() {
    if (!!this.theGrid) {
      return this.theGrid.api.getSortModel();
    } else {
      return [];
    }
  }

  onSortChanged() {
    let that: any = this;
    that.context.parent.internalFilterChange("sort");
    // TO DO check for clear caching data
  }

  deselectRow(rowData) {
    let selectedRows = this.gridOptionsSpecs.api.getSelectedNodes();
    for (let index in rowData) {
      let row = rowData[index];
      for (let i in selectedRows) {
        let myRow = selectedRows[i];
        if (row.data.carID === myRow.data.carID) {
          myRow.setSelected(false);
          //this.gridOptionsSpecs.api.deselectNode(myRow);
        }
      }
    }
  }

  clearSelection() {
    this.gridOptionsSpecs.api.deselectAll();
  }

  deselectRowsByObjectArr(rowData) {
    let selectedRows = this.gridOptionsSpecs.api.getSelectedNodes();
    for (let index in rowData) {
      let row = rowData[index];
      for (let i in selectedRows) {
        let myRow = selectedRows[i];
        if (row.carID === myRow.data.carID) {
          myRow.setSelected(false);
          //this.gridOptionsSpecs.api.deselectNode(myRow);
        }
      }
    }
  }


  formatCurrency(params) {
    return formatCurrency(params.value, params.context.currencySign);
  }

  formatYesNo(params) {
    if (params.value === 'false') {
      return 'no';
    }
    return 'yes';
  }

  onRowDoubleClicked(params: RowDoubleClickedEvent) {
    const ctx = params.context;
    params.node.setSelected(true);
    const selectedRows = params.api.getSelectedRows();
    ctx.parent.selectedCarsGrid.addSelectedCars(selectedRows);
    ctx.parent.checkEnableButton();
  }

  public applyFilter(params) {
    this.currentFilter = params;
    this.gridOptionsSpecs.api.onFilterChanged();
  }




}


