import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateRangeValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
  const validFrom = formGroup.get('validFrom')?.value;
  const validTo = formGroup.get('validTo')?.value;

  if (validFrom && validTo && new Date(validFrom) > new Date(validTo)) {
    return { dateRangeInvalid: true }; // Return validation error
  }
  return null; // Validation successful
};
