import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, metadata, filters } from '@environments/environment';

@Injectable()
export class AppConfigService {
  constructor(private http: HttpClient) { }
  loadEnv() {
    const jsonFile = `ext/appsettings.properties`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: IRuntimeConfig) => {

        if (response.backendRoot != null) {
          if (window.location.href.includes(response.alternativeDNS)) {
            response['root'] = "alternative";
          } else {
            response['root'] = "default";
          }

          Object.keys(environment).forEach(k => {
            if (k !== 'backendRoot' && (typeof environment[k] === 'string' || environment[k] instanceof String)) {
              let configLine: string = environment[k];
              environment[k] = configLine.replace(environment.backendRoot, response.backendRoot[response.root]);
            }
          });
          environment['backendRoot'] = response.backendRoot[response.root];
        }
        if (response.auditRoot != null) {
          environment['auditRoot'] = response.auditRoot;
        } else {
          console.log("Audit root endpoint is not provided!");
        }
        if (response.aboutLink != null) {
          environment['aboutLink'] = response.aboutLink;
        } else {
          console.log("About link is not provided!");
        }
        if (response.taxTooltips != null) {
          environment['taxesInformation'] = response.taxTooltips;
        } else {
          console.log("Taxes information object is not provided!");
        }
        if (response.filterParams != null) {
          environment['filterParams'] = response.filterParams;
        } else {
          console.log("Filter parameters for competitor vehicle is not provided!");
        }
        if (response.orgId != null) {
          environment['orgId'] = response.orgId;
        } else {
          console.log("Organisation ID is not provided!");
        }
        if (response.salesforceEndpoint != null) {
          environment['salesforceEndpoint'] = response.salesforceEndpoint;
        } else {
          console.log("Endpoint for datasetInfo is not provided!");
        }
        resolve();
      }).catch((response: any) => {
        //reject(`Could not load the config file`);
        console.log("Could not load external config file, using default!", response);
        resolve();
      });
    });
  }

  loadMetadata() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<any>(environment.metadataEndpoint).toPromise().then((result: IRuntimeConfig) => {
        if (result != null) {
          Object.keys(result).forEach((key) => {
            let element = result[key];
            var retval = '';
            var newArr = [];
            if (element.path !== undefined) {
              for (let ipath = 0; ipath < element.path.length; ipath++) {
                const path = element.path[ipath];
                var pathArr = path.split("/");
                retval = '';
                retval = "$[?(@.field=='" + pathArr[0] + "')]";
                for (let index = 1; index < pathArr.length; index++) {
                  const element = pathArr[index];
                  retval += ".children[?(@.field=='" + pathArr[index] + "')]";
                }
                newArr.push(retval);
              }
              element.path = newArr;
            }
            metadata[key]=element;
          });
        }
        console.log("Received metadata!", metadata);
        resolve();
      }).catch((response: any) => {
        //reject(`Could not load the config file`);
        console.log("Could not load metadata!", response);
        resolve();
      });
    });
  }

  loadFilters() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<any>(environment.dataFiltersEndpoint).toPromise().then((result: IRuntimeConfig) => {
        if (result != null) {
          Object.keys(result).forEach(x=> {
            filters[x] = result[x];
          });
        }
        console.log("Received filters!", filters);
        resolve();
      }).catch((response: any) => {
        //reject(`Could not load the config file`);
        console.log("Could not load filters!", response);
        resolve();
      });
    });
  }

}

export interface IRuntimeConfig {
  backendRoot: any
  alternativeDNS: string
  root: string
  auditRoot: string
  aboutLink: string
  taxTooltips: any
  orgId: string
  salesforceEndpoint: string
  filterParams: any
}
