const backendRoot = {
  default: "http://192.168.201.64.6060",
  alternative: "http://192.168.201.64.6060"
};
const root = "default";
const alternativeDNS = "192.168.201.64";
const auditRoot = "http://txn.qa.autovistacloud.net.int/transaction";
const aboutLinkDef = "https://cce-content-testdev.s3.eu-west-1.amazonaws.com/CCE_JSON/view.html#contentContact";
const orgId = "00D000000CrYc";
const salesforceEndpoint = "https://autovistagroupsf--codlakeqa.my.sales....?encoding=UTF-8";

export const metadata = {};
export const filters = {};
export const filterParams = {};
export const taxesInformation = {};
export const environment = {
  backendRoot: backendRoot[root],
  production: true,
  auditRoot : auditRoot,
  aboutLink : aboutLinkDef,
  taxesInformation : taxesInformation,
  filterParams: filterParams,
  orgId: orgId,
  salesforceEndpoint: salesforceEndpoint,
  metadataEndpoint: backendRoot[root] + "/calc/metadata",
  authEndpoint: backendRoot[root] + "/auth/login",
  resetPassEndpoint: backendRoot[root] + "/auth/resetPass",
  changePassEndpoint: backendRoot[root] + "/auth/changePass",
  uploadEnpoint:backendRoot[root] + "/upload/csv",
  carSpecEndpoint: backendRoot[root] + "/sorc/basket",
  paramScopeEndpoint: backendRoot[root] + "/amf/paramScope",
  paramFormEndpoint: backendRoot[root] + "/amf/paramForm",
  saveParamWithPreferencesEndpoint: backendRoot[root] + "/amf/saveParamWithPreferences",
  configuredParamsEndpoint: backendRoot[root] + "/amf/configuredParams",
  saveConfiguredParamsEndpoint: backendRoot[root] + "/amf/saveConfiguredParams",
  specsScopeEndpoint: backendRoot[root] + "/amf/specsScope",
  exportExelWithMaintenance: backendRoot[root] + "/export/withMaintenance",
  exportExelWithoutMaintenance: backendRoot[root] + "/export/withoutMaintenance",
  exportCSV: backendRoot[root] + "/export/csv",
  addVehicleEndpoint: backendRoot[root] + "/vehicle/addVehicle",
  callCalcEndpoint: backendRoot[root] + "/vehicle/callCalc",
  addNewSheetEndpoint: backendRoot[root] + "/vehicle/addNewSheet",
  equipmentEndpoint: backendRoot[root] + "/calc/equipment",
  loadCalcModelEndpoint: backendRoot[root] + "/calc/loadModel",
  saveCalcModelEndpoint: backendRoot[root] + "/calc/saveModel",
  sorkVehiclesEndpoint: backendRoot[root] + "/sorc/vehicles",
  loadUserPreferencesEndpoint: backendRoot[root] + "/amf/preferences",
  saveUserPreferencesEndpoint: backendRoot[root] + "/amf/savePreferences",
  utilsProxyEndpoint: backendRoot[root] + "/util/proxy",
  aboutInfoEndpoint: backendRoot[root] + "/auth/aboutInfo",
  getCceFilesEndpoint: backendRoot[root] + "/cce/getCceFiles",
  deleteCceFiles: backendRoot[root] + "/cce/deleteCceFiles",
  getSheetsFromCceFilesById: backendRoot[root] + "/cce/getSheetsFromCceFilesById",
  saveCceFileEndpoint: backendRoot[root] + "/cce/saveCceFile",
  updateCceFileMetaEndpoint: backendRoot[root] + "/cce/updateCceFileMeta",
  getCceFromDeviceEndpoint: backendRoot[root] + "/cce/openLocal",
  retrieveGroup : backendRoot[root] + "/admin/getGroupUsers",
  createUser : backendRoot[root] + "/admin/createUser",
  getUser : backendRoot[root] + "/admin/getUser",
  uploadUserFile : backendRoot[root] + "/admin/uploadUserFile",
  updateUser : backendRoot[root] + "/admin/updateUser",
  deleteUsers : backendRoot[root] + "/admin/deleteUsers",
  getOrganisation : backendRoot[root] + "/admin/getOrganisation",
  getNotifications : backendRoot[root] + "/notification/getNotifications",
  getNotificationsToShow : backendRoot[root] + "/notification/getNotificationsToShow",
  getNotificationsStatus : backendRoot[root] + "/notification/getNotificationsStatus",
  saveNotification : backendRoot[root] + "/notification/saveNotification",
  readNotification : backendRoot[root] + "/notification/readNotification",
  deleteNotification : backendRoot[root] + "/notification/deleteNotification",
  saveOrganisation : backendRoot[root] + "/admin/saveOrganisation",
  esacosEndpoint: backendRoot[root] + "/sorc/esacos",
  heatmapColorsEndpoint: backendRoot[root] + "/heatmap/getColors",
  addScenario:  backendRoot[root] + "/vehicle/addScenario",
  imgEndpoint: backendRoot[root] + "/sorc/images",
  updateEndpoint: backendRoot[root] + "/update/trigger",
  filterEndpoint: backendRoot[root] + "/specs/filter",
  defaultCompEndpoint: backendRoot[root] + "/specs/defaultCompetitors",
  combosEndpoint: backendRoot[root] + "/specs/combos",
  defaultBasketEndpoint: backendRoot[root] + "/specs/basket",
  dataFiltersEndpoint: backendRoot[root] + "/calc/filters"
};
