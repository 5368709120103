import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@app/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-show',
  templateUrl: './notification-show.component.html',
  styleUrls: ['./notification-show.component.scss']
})
export class NotificationShowComponent {


  notification: any;
  safeMessage: any;
  pipe = new DatePipe('en-US');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notServ: NotificationService,
    private dialogRef: MatDialogRef<NotificationShowComponent>,
    private sanitizer: DomSanitizer) {
    this.notification = data;
    this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(this.notification.message);
    // Ensure data is returned on close (click outside or Escape)
    this.dialogRef.beforeClosed().subscribe(() => {
      this.dialogRef.close(this.notification); // Return data before closing
    });

  }
  onClose() {
    this.dialogRef.close(this.notification);
  }
}
